<template>
    <div class="hwk-mamage">
        <van-form ref="form" @submit="onSubmit">
            <van-cell-group inset>
                <van-field v-model="username" autocomplete="on" name="用户名" label="用户名" placeholder="用户名" :rules="[{ required: true, message: '请填写用户名' }]" />
                <van-field v-model="password" autocomplete="on" current-password="" type="password" name="密码" label="密码" placeholder="密码" :rules="[{ required: true, message: '请填写密码' }]" />
            </van-cell-group>
            <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit">
                    提交
                </van-button>
            </div>
        </van-form>
    </div>
</template>
<script>
import { ref } from 'vue'
export default {
    name: 'hwkManage',
    components: {},
    data() {
        const onSubmit = values => {
            if ((values.用户名 == 'admin' || values.用户名 == 'zcx') && values.密码 == '123456') {
                localStorage.setItem('uname', values.用户名)
                this.$router.push({ path: '/eindex', query: { r: window.Math.random() } })
            }
        }
        return {
            username: '',
            password: '',
            onSubmit
        }
    },

    method: {
        onSubmit(values) {
            console.log('in..............', values)
        }
    }
}
</script>
<style scoped>
.hwk-mamage {
    width: 100%;
}
</style>
