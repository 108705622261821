var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hwk-mamage" },
    [
      _c(
        "van-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "van-cell-group",
            { attrs: { inset: "" } },
            [
              _c("van-field", {
                attrs: {
                  autocomplete: "on",
                  name: "用户名",
                  label: "用户名",
                  placeholder: "用户名",
                  rules: [{ required: true, message: "请填写用户名" }]
                },
                model: {
                  value: _vm.username,
                  callback: function($$v) {
                    _vm.username = $$v
                  },
                  expression: "username"
                }
              }),
              _c("van-field", {
                attrs: {
                  autocomplete: "on",
                  "current-password": "",
                  type: "password",
                  name: "密码",
                  label: "密码",
                  placeholder: "密码",
                  rules: [{ required: true, message: "请填写密码" }]
                },
                model: {
                  value: _vm.password,
                  callback: function($$v) {
                    _vm.password = $$v
                  },
                  expression: "password"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "16px" } },
            [
              _c(
                "van-button",
                {
                  attrs: {
                    round: "",
                    block: "",
                    type: "primary",
                    "native-type": "submit"
                  }
                },
                [_vm._v(" 提交 ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }